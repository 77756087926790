.header {
  background: $off-black;
  margin-bottom: $l-size;
  color: white;
  padding: $m-size 0;
}

.header__title {
  font-size: $xl-size;
  margin: 0;
}

.header__subtitle {
  color: $off-white;
  font-size: $m-size;
  font-weight: 500;
  margin: 0;
}
