//Add option error
.add-option-error {
  color: $off-white;
  font-style: italic;
  padding: 0 $m-size;
  margin-top: $m-size 0 0 0;
}

//Form
.add-option {
  display: flex;
  padding: $m-size;
}

.add-option__input {
  flex-grow: 1;
  background: $dark-blue;
  border: none;
  border-bottom: 0.3rem solid darken($dark-blue, 10%);
  margin-right: $s-size;
  color: $off-white;
  padding: $s-size;
}
