// Colors
$off-black: #20222b;
$off-white: #a5afd7;
$dark-blue: #333745;
$blue: #3c4251;
$light-blue: #464b5e;
$purple: #8357c5;

//Spacing
$m-size: 1.6rem;
$l-size: 4.8rem;
$xl-size: 3.2rem;
$s-size: 1.2rem;
