//Big Button
.big-button {
  background: $purple;
  border: none;
  border-bottom: 0.6rem solid darken($purple, 10%);
  color: white;
  font-weight: bold;
  font-size: $l-size;
  width: 100%;
  margin-bottom: $xl-size;
  padding: 2.4rem;
}

.big-button:disabled {
  opacity: 0.5;
}

//Add Option Button
.button {
  background: $purple;
  border: none;
  border-bottom: 0.3rem solid darken($purple, 10%);
  color: white;
  font-weight: 500;
  padding: $s-size;
}

.button--link {
  background: none;
  border: none;
  color: $off-white;
  padding: 0;
}

